/*!
 * Core Theme JS-functionality
 * @version 1.0.0
 */

'use strict';

/* global jQuery */

jQuery.noConflict();

( function( $ ) {

	/**
	 * Create the theme_core Object
	 */
	const theme_core = {

		/**
		 * File skip-link-focus-fix.js.
		 *
		 * Helps with accessibility for keyboard only users.
		 * @link https://git.io/vWdr2
		 */
		fileSkipLinkFocusFix : function(){
			var isIe = /(trident|msie)/i.test( navigator.userAgent );

			if ( isIe && document.getElementById && window.addEventListener ) {
				window.addEventListener( 'hashchange', function() {
					var id = location.hash.substring( 1 ),
						element;

					if ( ! ( /^[A-z0-9_-]+$/.test( id ) ) ) {
						return;
					}

					element = document.getElementById( id );

					if ( element ) {
						if ( ! ( /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) ) {
							element.tabIndex = -1;
						}

						element.focus();
					}
				}, false );
			}
		},

		/*
		 * Accessibility tabbing on primary navigation menu
		 * hoverIntent v1.9.0 // 2014.06.29 // jQuery v1.9.1+
		 * @link http://cherne.net/brian/resources/jquery.hoverIntent.html
		 */
		tabHelper : function() {
			if ( jQuery().hoverIntent ) {
				$('#site-navigation li').hoverIntent(function() {
					if($(this).find('ul.sub-menu').length>0) {
						$(this).find('ul.sub-menu').first().slideDown(200);
					}
				}, function() {
					if($(this).find('ul.sub-menu').length>0) {
						$(this).find('ul.sub-menu').slideUp(150);
					}
				});

				$('#site-navigation li:has("ul.sub-menu")').on('keyup', function(e) {
					if(e.keyCode === 9) {
						$(this).find('ul.sub-menu').first().slideDown(200);
					}
				});

				$('#site-navigation li > ul > li:last-child > a').on('keydown', function(e) {
					if ( e.keyCode === 9 && $(this).parent('li').children('ul').length === 0 ) {
						$(this).parent('li').parent('ul').slideUp(150);
					}
				});
			}
		},

		/*
		 * mMenu plugin
		 * @link http://mmenu.frebsite.nl/
		 * v7.0.3
		 */
		mMenu : function() {
			if( jQuery().mmenu ){
				$('#mobile-navigation').mmenu({
					extensions: [
						'border-full',
						'pagedim-black',
						'theme-dark',
						'position-back',
						'position-right'
//						'fx-panels-none'
					],
					wrappers: [
						'wordpress',
						'bootstrap4'
					],
					navbar : {
						add: true,
						title: 'Tromsø ASVO'
					}
				});
			}
		},

		/**
		 * Setup Fancybox
		 * @link https://fancyapps.com/fancybox/3/docs/
		 * v3.5.1
		 */
		fancyBoxSettings : function() {
			if( jQuery().fancybox ){
				$('[data-fancybox]').fancybox({});
			}
		},

		/**
		 * Prevent default behaviour on certain links
		 */
		preventDefaults : function(){
			$('#menu-toggle').click(function(e){
				e.preventDefault();
			});

			$('#menu-close').click(function(e){
				e.preventDefault();
			});
		},
		
		matchHeight : function(){
			$('.box-color .box-headline').matchHeight();
			$('.paroller').paroller();
		},

		/**
		 * Initialize the JS functionality needed for this theme
		 */
		init : function(){
			theme_core.fileSkipLinkFocusFix();
			theme_core.tabHelper();
			theme_core.mMenu();
			theme_core.fancyBoxSettings();
			theme_core.preventDefaults();
			theme_core.matchHeight();
		}
	}

	/**
	 * Instantiate the "theme_core" Object
	 */
	theme_core.init();

} )( jQuery );